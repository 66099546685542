<!--  -->
<template>
    <div class='page-history-summary flex-center'>
        <component-bcg url="history/bcg1.png"></component-bcg>
        <div class="bcg2">
            <div class="l d1">
                <div class="head">
                    <img :src="`${PARAMS.assetsUrlV2_0}/history/b1.png`" class="h-img">
                    <span class="text-normal bold text-blue-color h-txt" v-if="isShow==0">{{"胜率"}}</span>
                    <span class="text-normal bold text-blue-color h-txt" v-if="isShow==1">{{"正确率"}}</span>
                </div>
                <span class="text-normal bold text-blue-color pie-txt">{{(count.winRate||0)+"%"}}</span>
                <div class="pie d2">
                    <div class="z1">
                    </div>
                    <pieChart :percentageInfo="count.winRate||0" :idName="1" ref="game-pie"></pieChart>
                    <!-- <div class="b2">
                        <div class="s1"></div>
                    </div> -->
                </div>
                <div class="content">
                    <div class="t">
                        <div class="item">
                            <span class="text-normal bold text-blue-color con-txt">{{"近7天"}}</span>
                            <div class="play-num">
                                <div class="num">
                                    <span class="text-normal bold text-blue-color num-txt">{{count.count07}}</span>
                                    <div class="x1"></div>
                                </div>
                                <span v-if="isShow==1" class="text-normal bold text-blue-color con-txt">{{"题"}}</span>
                                <span v-if="isShow==0" class="text-normal bold text-blue-color con-txt">{{"局"}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="c">
                        <div class="item">
                            <span class="text-normal bold text-blue-color con-txt">{{"近30天"}}</span>
                            <div class="play-num">
                                <div class="num">
                                    <span class="text-normal bold text-blue-color num-txt">{{count.count30}}</span>
                                    <div class="x1"></div>
                                </div>
                                <span v-if="isShow==1" class="text-normal bold text-blue-color con-txt">{{"题"}}</span>
                                <span v-if="isShow==0" class="text-normal bold text-blue-color con-txt">{{"局"}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="b">
                        <div class="item">
                            <span class="text-normal bold text-blue-color con-txt">{{"累计"}}</span>
                            <div class="play-num">
                                <div class="num">
                                    <span class="text-normal bold text-blue-color num-txt">{{count.countAll}}</span>
                                    <div class="x1"></div>
                                </div>
                                <span v-if="isShow==1" class="text-normal bold text-blue-color con-txt">{{"题"}}</span>
                                <span v-if="isShow==0" class="text-normal bold text-blue-color con-txt">{{"局"}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="sum1" v-if="isShow==1" @click="tagHandle()">
                <span class="text-normal bold sum1-txt">{{"棋局统计"}}</span>
            </div>
            <div class="sum2 sum2-txt" v-if="isShow==1">
                <span class="text-normal bold sum2-txt">{{"闯关统计"}}</span>
            </div>
            <div class="sum3" v-if="isShow==0">
                <span class="text-normal bold sum1-txt">{{"棋局统计"}}</span>
            </div>
            <div class="sum4 sum2-txt" v-if="isShow==0" @click="tagHandle()">
                <span class="text-normal bold sum2-txt">{{"闯关统计"}}</span>
            </div>
            <div class="r">
                <div class="head">
                    <div class="title">
                        <span class="text-normal bold text-yel-color time-row">{{"时间"}}</span>
                        <span class="text-normal bold text-yel-color black-row">{{"黑棋"}}</span>
                        <span class="text-normal bold text-yel-color white-row">{{"白棋"}}</span>
                        <span class="text-normal bold text-yel-color hand-row">{{"手数"}}</span>
                        <span class="text-normal bold text-yel-color result-row">{{"结果"}}</span>
                        <span class="empty"></span>
                    </div>
                    <div class="f1"></div>
                </div>
                <div class="items-empty" v-if="dataLength==0&&loadingFlag">
                    <span class="text-normal bold text-yel-color e">{{"当前棋谱还是空的,"}}</span>
                    <span class="text-normal bold text-yel-color e">{{"快去对弈吧~"}}</span>
                    <div class="btn scale-button" @click="goPlay()">
                        <confirmPlay>
                            <span class="text-normal blod b">{{"去对弈"}}</span>
                        </confirmPlay>
                    </div>
                </div>
                <div class="items mini-scrollbar max-height" 
                @scroll="scrolLoad"
                ref="commentContent"
                v-if="dataLength!=0" 
                >
                    <div ref="commentBox">
                        <div class="d3" v-for="(v,i) in dataList" :key="i">
                            <div class="j1">
                                <div class="item">
                                    <span class="text-normal text-yellow-color i time-row">{{v.date}}</span>
                                    <span class="text-normal text-yellow-color i black-row">{{v.pb}}</span>
                                    <span class="text-normal text-yellow-color i white-row">{{v.pw}}</span>
                                    <span class="text-normal text-yellow-color i hand-row">{{v.totalNum}}</span>
                                    <div class="result-row">
                                        <!-- <span class="text-normal bold text-yellow-color">{{v.analyzeStatus}}</span> -->
                                        <result class="text-normal text-yellow-color i" :result="v.gameResult"></result>
                                    </div>
                                    <div class="btn scale-button">
                                        <confirmPlay @click="gotoReview(v)">
                                            <!-- <span class="text-normal blod text-red-color i flex-center">{{"AI复盘"}}</span> -->
                                            <span class="text-normal blod text-red-color i flex-center">{{"查看"}}</span>
                                        </confirmPlay>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <chatLoading ref="chatLoading" :loading.sync="loading" color="#D2B59A"></chatLoading>
                </div>
                <!-- <div class="d4 mini-scrollbar">
                    <div class="h1 scale-button"></div>
                </div> -->
            </div>
        </div>
    </div>
</template>

<script>
import ComponentBcg from '../../../components/componentBcg.vue';
import apiClass from '@/API/page/history'
import pieChart from '../components/pieChart.vue'
import chatLoading from '../components/loading.vue'

    export default {
        name: '',
        props: {
            appStyle: Object,
        },
        data() {
            return {
                api: new apiClass(),
                num:0,
                isShow:0,
                count:{},
                countA:{},
                countB:{},
                dataList:[],
                loading: false,
                page:0,
                size: 8,
                listCount:0,
                // gameType: '60',//高途:6，高途人机:60，高途人人:68
                gameType: '6',//高途:6，高途人机:60，高途人人:68
                loadingFlag: false,
            };
        },
        methods: {
            pageInit(){
                this.playCount()
                this.dataCount()
                this.gameList()
                this.eventSet()
            },
            goPlay(){
                this._routeHand.goto({
                    name: 'game-list',
                    type: 'push',
                })
            },
            async dataCount(){
                let {usercode: user_code} = this
                let res = await this.api.dataCount({user_code})
                if(res) {
                    let {userGameCount,userGameDay07Count,userGameDay30Count,winRate} = res.countAll
                    let info = {
                        count07:userGameDay07Count,
                        count30:userGameDay30Count,
                        countAll:userGameCount,
                        winRate:Math.round(winRate*100),
                    }
                    this.count = info
                    this.countA = info
                }
            },
            async gameList(){
                let {usercode: user_code, page, size, gameType} = this
                let opt = {user_code, page, size, gameType}
                this.loadingFlag = false
                let res = await this.api.gameList(opt)
                this.loadingFlag = true

                if(res) {
                    let {gameMetas} = res
                    this.dataList = this.dataHandle(gameMetas)
                }
            },
            async scrolLoad(){
                let {loading,usercode: user_code,page,size,dataLength:length,listCount:total} = this
                if(loading == true) return
                if(!this.$refs['commentContent']) return
                let index = this.$refs['commentContent'].scrollTop
                let contentHeight = this.$refs['commentContent'].offsetHeight
                let boxHeight = this.$refs['commentBox'].offsetHeight
                if((boxHeight - index + 30) > contentHeight) return
                this.loading = true
                if(length==total) {
                    this.loading = false
                    return
                }
                page++
                let res = await this.api.gameList({user_code, page,size, noLoad: true, gameType: this.gameType})
                if(res){
                    let {gameMetas,count} = res
                    this.listCount = count
                    if(gameMetas.length){
                        this.page = page
                        let r = this.dataHandle(gameMetas)
                        this.dataList = this.dataList.concat(r)
                    }
                }
                this.loading = false
            },
            dataHandle(val){
                let list = val || []
                let r = []
                for (const i of list) {
                    let {playTime,pb,pw,moveNum,startMoveNum,gameResult,id,analyzeStatus} = i
                    let {day,month,year} = playTime.date
                    if(month<10) month = '0'+ month
                    if(day<10) day = '0' + day
                    let date = `${year+'-'+month+'-'+day}`//日期
                    let totalNum =  moveNum - startMoveNum//手数
                    let info = {
                        date,
                        pb,
                        pw,
                        totalNum,
                        gameResult,
                        id,
                        analyzeStatus,
                    }
                    r.push(info)
                }
                return r
            },
            async playCount(){
                let {usercode: user_code} = this
                let res = await this.api.playCount({user_code})
                if(res) {
                    let {userExerciseD07Count,userExerciseD30Count,userExerciseCount,userExerciseCorrectRate} = res.countAll
                    let info = {
                        count07:userExerciseD07Count,
                        count30:userExerciseD30Count,
                        countAll:userExerciseCount,
                        winRate:Math.round(userExerciseCorrectRate*100),
                    }
                    this.countB = info
                }
            },
            // 点击切换标签
            tagHandle(){
                let {isShow} = this
                if(isShow==0)this.isShow = 1;
                if(isShow==1)this.isShow = 0;
            },
            sizeChange(){
                if(this.$refs['game-pie']) this.$refs['game-pie'].sizeChange()
            },
            eventSet(){
                this.eventOff()
                this._common.eventSet('onresize', e => {
                    this.sizeChange()
                })
            },
            eventOff(){
                this._common.eventRemove('onresize')
            },
            async gotoReview(val){
                let {id: gameId,analyzeStatus: status} = val || {}
                if(!gameId) return
                this.toReview(gameId)
                // if(status != 0){
                //     this.toReview(gameId)
                //     return
                // }
                // await this.getGameReportCount(gameId)
            },
            toReview(val){
                this._routeHand.goto({
                    name: 'game-review-summary',
                    params: {gameId: val}, 
                    // query: {backName: this.$route.name},
                })
            },
            async changeUserGameReport(){
                let {usercode: user_code} = this
                let opt = {
                    user_code,
                    // school_id,
                }
                let res = await this.api.changeUserGameReport(opt)
                return res
            },
            async getGameReportCount(val){
                let {usercode: user_code} = this
                let opt = {
                    user_code,
                    // school_id,
                }
                let res = await this.api.getGameReportCount(opt)
                if(!res) return
                let {dailyRemainCount: count} = res
                if(count >= 1){
                    this.toReview(val)
                }
                if(count < 1) {
                    let result = await this.confirmNotice()
                    if(result==0) {
                        let res =  await this.changeUserGameReport()
                        if(!res) return
                        this.toReview(val)
                    }
                }
            },
            async confirmNotice(){
                let res = await this._common.confirmPro.open({
                    message:'今天的复盘次数已经超过上限(3次),',
                    subMessage:`是否确认消耗30棋石复盘此局？`,
                    confirmType:'c6',
                    buttons: [
                        {title: '取消', label: 1, type: 'cancel'},
                        {title: '确定', label: 0, type: 'confirm'},
                    ]
                })
                res = res || {}
                return res.label
            }
        },
        created() {
            this.pageInit()
        },
        components: {
            ComponentBcg,
            pieChart,
            chatLoading,
        },
        computed: {
            usercode(){
                let {
                    app: {usercode} = {}
                } = this.$store.state
                return usercode
            },
            dataLength(){
                let {dataList} = this
                return dataList.length
            },
        },
        watch: {
            appStyle: {
                handler(val){
                    this.sizeChange()
                },
                deep: true,
                immediate: true
            },
            isShow: {
                handler(val){
                    if(val==0) this.count = this.countA
                    if(val==1) this.count = this.countB
                },
                deep: true,
                immediate: true
            }
        },
        mounted() {
        },
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {
            this.eventOff()
        }, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang='less' scoped>
    .page-history-summary{
        @text-blue-color:#73769C;
        @text-yel-color:#A48974;
        @text-red-color:#872E45;
        @text-yellow-color:#977C68;
        .text-blue-color{
            color: @text-blue-color;
        }
        .text-yel-color{
            color: @text-yel-color;
        }
        .text-yellow-color{
            color: @text-yellow-color;
        }
        .text-red-color{
            color: @text-red-color;
        }
        width: 100%;
        padding-right: @app-padding-side;
        height: 100%;
        .bcg2{
            .background-img-max(url('@{assetsUrlV2_0}/history/bcg2.png'));
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            width: 665px;
            height: 360px;
            .l.d1{
                .background-img-max(url('@{assetsUrlV2_0}/history/d1.png'));
                width: 146px;
                height: 242px;
                position: absolute;
                top: 56px;
                left: 51px;//+2
                .head{
                    .h-img{
                        width: 6px;
                        height: 12px;
                        position: absolute;
                        top: 9px;
                        left: 6px;
                    }
                    .h-txt{
                        position: absolute;
                        top: 9px;
                        left: 15px;
                        font-size: 13px;
                    }
                }
                .pie-txt{
                    position: absolute;
                    top: 16px;//-4
                    left: 103px;
                    // font-size: 17px;
                }
                .pie.d2{
                    .background-img-max(url('@{assetsUrlV2_0}/history/d2.png'));
                    width: 108px;
                    height: 108px;
                    position: absolute;
                    top: 22px;
                    left: 19px;
                    .z1{
                        .background-img-max(url('@{assetsUrlV2_0}/history/z1.png'));
                        width: 31px;
                        height: 16px;
                        position: absolute;
                        top: 1px;//-3
                        left: 53.5px;//+1
                    }
                    .b2{
                        .background-img-max(url('@{assetsUrlV2_0}/history/b2.png'));
                        position: absolute;
                        top: 16px;
                        left: 16px;
                        width: 77px;
                        height: 77px;
                        .s1{
                            .background-img-max(url('@{assetsUrlV2_0}/history/s1.png'));
                            position: absolute;
                            top: 0px;
                            left: 38px;
                            width: 39px;
                            height: 61px;
                        }
                    }
                }
                .content{
                    .t,.c,.b{
                        width: 100%;
                        padding: 0 25px 0 18px;
                        position: absolute;
                        .item{
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            .con-txt{
                                font-size: 10px;
                                transform: scale(0.9);
                            }
                            .play-num{
                                display: flex;
                                align-items: center;
                                .num{
                                    display: flex;
                                    flex-direction: column;
                                    padding-right: 2px;
                                    .num-txt{
                                        // padding-right: 2px;
                                        font-size: 17px;
                                    }
                                    .x1{
                                        .background-img-max(url('@{assetsUrlV2_0}/history/x1.png'));
                                        height: 2px;
                                        width: 100%;
                                        transform: scale(0.9);
                                        margin-top: 1px;
                                    }
                                }
                            }
                        }
                    }
                    .t{
                        top: 145px;
                    }
                    .c{
                        top: 177px;
                    }
                    .b{
                        top: 207px;
                    }
                }
            }
            .on{
                z-index: 1;
            }
            .sum1{
                cursor: pointer;
                .background-img-max(url('@{assetsUrlV2_0}/history/sum1.png'));
                width: 50px;
                height: 29px;
                position: absolute;
                bottom: 34px;
                left: 79px;//+1
                .sum1-txt{
                    color: #8E6F22;
                    font-size: 10px;
                    transform: scale(0.9);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-top: 4px;
                }
            }
            .sum3{
                cursor: pointer;
                .background-img-max(url('@{assetsUrlV2_0}/history/sum3.png'));
                width: 50px;
                height: 29px;
                position: absolute;
                bottom: 34px;
                left: 79px;//+1
                .sum1-txt{
                    color: #8E6F22;
                    font-size: 10px;
                    transform: scale(0.9);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-top: 4px;
                }
            }
            .sum2{
                cursor: pointer;
                .background-img-max(url('@{assetsUrlV2_0}/history/sum2.png'));
                width: 50px;
                height: 29px;
                position: absolute;
                bottom: 34px;
                left: 134px;//+1
                .sum2-txt{
                    color: #A32A3D;
                    font-size: 10px;
                    transform: scale(0.9);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-top: 4px;
                }
            }
            .sum4{
                cursor: pointer;
                .background-img-max(url('@{assetsUrlV2_0}/history/sum4.png'));
                width: 50px;
                height: 29px;
                position: absolute;
                bottom: 34px;
                left: 134px;//+1
                .sum2-txt{
                    color: #A32A3D;
                    font-size: 10px;
                    transform: scale(0.9);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-top: 4px;
                }
            }
            .r{
                .head{
                    width: 299px;
                    position: absolute;
                    left: 259px;
                    top: 65px;
                    .title{
                        width: 325px;
                        position: relative;
                        left: -14px;
                        display: flex;
                        span{
                            font-size: 10px;
                            transform: scale(0.9);
                        }
                        .time-row{
                            flex: 3;
                        }
                        .black-row{
                            flex: 2;
                        }
                        .white-row{
                            flex: 2;
                        }
                        .hand-row{
                            flex: 1.2;
                        }
                        .result-row{
                            margin: 0 -20px 0 -20px;
                            flex: 4.7;
                        }
                        .empty{
                            display: block;
                            width: 57px;
                        }
                    }
                    .f1{
                        .background-img-max(url('@{assetsUrlV2_0}/history/f1.png'));
                        width: 299px;
                        height: 2px;
                        margin-top: 3px;
                    } 
                }
                .items-empty{
                    position: absolute;
                    width: 299px;
                    left: 259px;
                    top: 160px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    .e{
                        font-size: 12px;
                        color: #D2B59A;
                    }
                    .b{
                        font-size: 12px;
                    }
                    .btn{
                        // .background-img-max(url('@{assetsUrlV2_0}/history/btn1.png'));
                        width: 75px;
                        height: 29px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-top: 11px;
                    }
                }
                .items{
                    position: absolute;
                    top: 88px;
                    left: 240px;
                    width: 346px;
                    height: 217px;
                    .d3{
                        .background-img-max(url('@{assetsUrlV2_0}/history/d3.png'));
                        width: 333px;
                        height: 31px;
                        padding: 0px 4px 5px;//top-1
                        .j1{
                            .background-img-max(url('@{assetsUrlV2_0}/history/j1.png'));
                            width: 325px;
                            height: 26px;
                            display: flex;
                            align-items: center;
                            .item{
                                width: 100%;
                                display: flex;
                                align-items: center;
                                .i{
                                    font-size: 10px;
                                    transform: scale(0.8);
                                    font-weight: 600;
                                }
                                .btn{
                                    // .background-img-max(url('@{assetsUrlV2_0}/history/btn1.png'));
                                    width: 47px;
                                    height: 19px;
                                    font-size: 8px;
                                    // transform: scale(0.8);
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    margin: 0 4px 0 5px;
                                }
                                .time-row{
                                    flex: 3;
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                }
                                .black-row{
                                    flex: 2;
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                }
                                .white-row{
                                    flex: 2;
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                }
                                .hand-row{
                                    flex: 1.2;
                                }
                                .result-row{
                                    flex: 4.7;
                                    transform: scale(0.8);
                                    margin: 0 -20px 0 -20px;
                                    text-align: center;
                                }
                            }
                        }
                    }
                }
                .d4{
                    // .background-img-max(url('@{assetsUrlV2_0}/history/d4.png'));
                    width: 8px;
                    height: 262px;
                    position: absolute;
                    right: 78px;
                    top: 51px;
                    .h1{
                        // .background-img-max(url('@{assetsUrlV2_0}/history/h1.png'));
                        width: 8px;
                        height: 58px;
                        position: absolute;
                        top: 0;
                        left: 0;
                    }
                }
            }
        }
        /deep/.com-confirm-button{
            width: 100%;
            height: 100%;
        }
        // 滚动条
        .mini-scrollbar::-webkit-scrollbar{
            width: 8px;
            // .background-img-max(url('@{assetsUrlV2_0}/avatar/c31.png'));
        }
        .mini-scrollbar-x::-webkit-scrollbar{
            height: 4px;
        }
        .mini-scrollbar::-webkit-scrollbar-thumb, .mini-scrollbar-x::-webkit-scrollbar-thumb{
            border-radius: 10px;
            // -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
            // background: rgba(0,0,0,0.2);
            .background-img-max(url('@{assetsUrlV2_0}/history/h1_tem.png'));
            // .background-img-max(url('@{assetsUrlV2_0}/avatar/c29_1.png'));

            // background: #FFFAE6;
            // background: url('@{assetsUrlV2_0}/avatar/c30.png') no-repeat;
            // background-position: center;
            
        }
        .mini-scrollbar::-webkit-scrollbar-track-piece{
            // background: red;
            // .background-img-max(url('@{assetsUrlV2_0}/avatar/c31.png'));
        }
        .mini-scrollbar::-webkit-scrollbar-thumb::before{
            content: '';
            .background-img-max(url('@{assetsUrlV2_0}/history/h1.png'));
        }
        .mini-scrollbar::-webkit-scrollbar-track, .mini-scrollbar-x::-webkit-scrollbar-track{
            // -webkit-box-shadow: inset 0 0 5px #C7946A;
            border-radius: 10px;
            // background: rgba(178,123,85,0.1);
            // background: #B27B55;
            background-image: linear-gradient(to right, #C8B09A, #DBC7B6, #C8B09A);
            // .background-img-max(url('@{assetsUrlV2_0}/avatar/c31.png'));
        }
        .mini-scrollbar{
            overflow-y: auto;
            overflow-x: hidden;
        }
    }
</style>